import React, { useState } from 'react';

const AgeCalculator = () => {
  const [birthdate, setBirthdate] = useState('');
  const [age, setAge] = useState(null);

  const calculateAge = () => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Age Calculator</h1>
      <input 
        type="date" 
        value={birthdate} 
        onChange={(e) =>{console.log(e.target.value);
             setBirthdate(e.target.value)}} 
      />
      <button onClick={calculateAge} style={{ marginLeft: '10px' }}>
        Calculate Age
      </button>
      {age !== null && (
        <h2>Your Age is: {age}</h2>
      )}
    </div>
  );
};

export default AgeCalculator;
